.tab-container {
    position: relative;
    right: 5px;
    margin-top: 12px;
    overflow: hidden;
}

.tab-container button {
    border-radius: 5px 5px 0 0 ;
}
