.selectedBox {
    width: 30%;
    margin: 0;
    padding: 2% 2% 2% 2% ;
    position: absolute;
    top: 5%;
    right: 5%;
}

.closeButton {
    background-color: transparent;
    width: 15%;
    height: 15% ;
}
.closeButton img {
    width: 100%;
    height: 100%;
}

.children {
    position: absolute;
    top: 90px;
    left: 240px;
}

.farmButtons {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 85px;
    left: 380px;

}