.table-shadow {
    margin: 0 0 20px 0;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.grid-header {
    font-size: large;
    /*background-color: lightgrey;*/
}

.table-shadow a {
    display: inline;
    width: 100%;
    vertical-align: middle;
    text-decoration: none;
    transition: 0.3s;
    color: black;
}

.table-shadow a:hover {
    color: grey;
}

.fmButtons{
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.scroll-cell {
    overflow-y: scroll !important;
    align-items: start !important;
}

.scroll-cell ul {
    margin: 0;
    padding-left: 5px;
}

.MuiAlert-action {
    align-items: flex-end !important;
    padding-bottom: 8px !important;
}