.Calendar {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.componentBox {
    /*width: 400px;*/
    margin: 0 0 20px 0;
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.boxTitle {
    margin: 0 20px 20px 0;
}

.noMarginTop {
    margin-top: 0px;
}

.smallMarginTop {
    margin-top: 10px;
}

.modifyEvent p, .selectedEvent p {
    margin: 0;
}

.rbc-day-bg {
    background: var(--day);
}

.rbc-off-range-bg {
    background: var(--off-range);
}

.rbc-today {
    background: var(--today);
}

.rbc-toolbar button {
    color: var(--text);
}

.rbc-toolbar button.rbc-active {
    color: black;
}

.rbc-header {
    background: var(--header);
}

.rbc-month-view {
    border-radius: 5px;
}

.rbc-month-view :last-child :first-child :first-child {
    border-radius: 0 0 0 5px;
}

.rbc-row-bg :last-child {
    border-radius: 0 0 5px 0;
}

.rbc-month-header :last-child{
    border-radius: 0 5px 0 0;
}

.rbc-month-header :first-child {
    border-radius: 5px 0 0 0;
}

.rbc-events-container {
    width: 100% !important;
}