.sidebar a {
    padding: 10px 20px 5px 20px;
    text-decoration: none;
    font-size: 16px;
    color: #818181;
    transition: 0.3s;
}

.sidebar a:hover {
    color: #f1f1f1;
}
